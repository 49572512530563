<template>
  <Stack tag="section" gap="md" direction="col" v-if="branch" class="pb">
    <Stack tag="div" gap="none" direction="col" data-test-id="branchMenuAddress">
      <Text tag="p" size="md">{{ branchDetails.address_line_1 }},</Text>
      <Text tag="p" size="md">{{ branchDetails.address_line_2 }},</Text>
      <Text tag="p" size="md" class="mb-2xs">{{ branchDetails.address_line_3 }} {{ branchDetails.postcode }}</Text>

      <NuxtLink
        :to="branchDetails.googleMapsLink"
        class="mb-xs text-mkm-blue-light"
        aria-label="See on Google Maps"
        data-test-id="seeOnMapLink"
        data-fs="seeOnMapLink"
      >
        See on map
      </NuxtLink>
      <NuxtLink
        :to="`tel:${branchDetails.phone}`"
        @click="firePhoneEvent"
        class="mb-2xs text-mkm-blue-light"
        aria-label="Call branch"
        data-fs="branchMenuPhone"
        data-test-id="branchMenuPhone"
      >
        {{ branchDetails.phone }}
      </NuxtLink>
      <NuxtLink
        :to="`mailto:${branchDetails.email}`"
        @click="fireEmailEvent"
        class="text-mkm-blue-light"
        aria-label="Email branch"
        data-test-id="branchMenuEmail"
        data-fs="branchMenuEmail"
      >
        {{ branchDetails.email }}
      </NuxtLink>
    </Stack>

    <Stack tag="div" direction="col" gap="sm" class="w-full">
      <Text weight="bold" :class="openStatusStyle">{{ isStoreOpenText }}</Text>
      <ul class="w-full" data-test-id="openHours">
        <li v-for="(workTime, index) in branchWorkingTimes" :key="index" class="flex">
          <Text class="capitalize !w-[100px] mr-xl" :class="index === 0 && '!font-bold'">{{
            Object.keys(workTime).toString()
          }}</Text>

          <div v-for="(day, i) in branchWorkingTimes[index]" :key="i + 2">
            <Text v-if="day" :class="index === 0 && '!font-bold'">{{ day.opens_at }} - {{ day.closes_at }}</Text>
            <Text v-else>Closed</Text>
          </div>
        </li>
      </ul>
    </Stack>

    <Stack tag="div" direction="col" gap="xs" class="w-full">
      <Button
        data-test-id="selectBranchButton"
        :variant="selectedBranch && branch.name === selectedBranch.name ? 'success' : 'secondary'"
        size="lg"
        class="!w-full !justify-center"
        v-if="currentStep !== Steps.details"
        @click="handleBranchSelect"
      >
        <template #before v-if="selectedBranch && branch.name === selectedBranch.name">
          <Icon name="check-thin" :size="18" />
        </template>
        {{ selectedBranch && branch.name === selectedBranch.name ? "Branch Selected" : "Select This Branch" }}
      </Button>

      <NuxtLink
        :to="`/branches/${branch.slug}`"
        v-if="currentStep === Steps.details"
        class="w-full"
        @click="setBranchTray"
        no-prefetch
      >
        <Button
          data-test-id="visitBranchPageButton"
          aria-label="Visit branch page"
          variant="secondary"
          size="lg"
          class="!w-full !justify-center"
          >Visit Branch Page</Button
        >
      </NuxtLink>

      <Button
        data-test-id="chooseDifferentBranchButton"
        variant="outline"
        size="lg"
        class="!w-full !justify-center whitespace-nowrap"
        aria-label="Choose different branch"
        v-if="currentStep === Steps.details"
        @click="updateSteps(Steps.search)"
        >Choose Different Branch</Button
      >

      <NuxtLink
        :to="`/branches/${branch.slug}`"
        v-if="currentStep !== Steps.details"
        class="w-full"
        @click="setBranchTray"
      >
        <Button
          data-test-id="goToBranchPageButton"
          aria-label="Visit branch page"
          variant="outline"
          size="lg"
          class="!w-full !justify-center"
          >Go To Branch Page</Button
        >
      </NuxtLink>
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
import { BranchTrayDetailsProps, Steps } from "@/composables/useBranchTray/branchTrayTypes";
import dayjs from "dayjs";
import { reorder } from "mkm-avengers";
import { useCustomEvents } from "mkm-gtm";
import { Branch, WorkingTime } from "mkm-types";

const props = defineProps<BranchTrayDetailsProps>();
const { branch } = toRefs(props);

const { updateSteps, selectedTrayBranch, currentStep } = useBranchTray();
const { branchTray, setBranchTray, setMergeWarning } = useUIState();
const { fetchCart, cart } = useCart();
const { selectedBranch, selectBranch } = useBranches();
const { user } = useUser();
const { isStoreOpenText, openStatusStyle } = useBranchWorkingTime(branch.value || ({} as Branch));
const { pushCustomEvent, CustomEvents } = useCustomEvents();

const branchWorkingTimes = ref();

const currentDayIndex = dayjs().day();

const branchDetails = computed(() => {
  if (!branch.value) {
    return {};
  }

  const { address_line_1, address_line_2, address_line_3, address_line_4, address_line_5, postcode } =
    branch?.value?.addresses?.collection ?? [];

  const { phone, email } = branch?.value.contacts ?? {};

  const googleMapsLink = `https://www.google.com/maps/place/${Number(branch?.value.coordinates?.lat)},${Number(
    branch?.value.coordinates?.lng,
  )}`;

  return {
    address_line_1,
    address_line_2,
    address_line_3,
    address_line_4,
    address_line_5,
    postcode,
    phone,
    email,
    googleMapsLink,
  };
});
const currentSelectedBranchId = ref<string | null>(null);

const handleBranchSelect = async () => {
  await selectBranch(Number(branch.value?.id));

  // Want to show the selcted state briefly before closing the tray
  setTimeout(() => {
    setBranchTray();
    updateSteps(Steps.details);
  }, 750);

  await fetchCart();

  if (cart.value && cart?.value?.line_items?.length) {
    setMergeWarning(true, Number(currentSelectedBranchId.value));
  }
};

onMounted(() => {
  if (branch.value) {
    currentSelectedBranchId.value = selectedBranch?.value?.id ?? null;
  }

  if (branch.value) {
    branchWorkingTimes.value =
      branch?.value.working_times && typeof branch?.value.working_times === "object"
        ? reorder(
            Object.entries(branch?.value.working_times as { [day: string]: WorkingTime }).map(([day, workingTime]) => ({
              [day]: workingTime,
            })),
            currentDayIndex - 1,
          )
        : null;
  }
});

watch(
  [selectedBranch, selectedTrayBranch, branch, currentStep, branchTray],
  () => {
    if (selectedBranch.value || selectedTrayBranch.value || branch.value || currentStep.value) {
      branchWorkingTimes.value = reorder(
        Object.entries(branch?.value?.working_times as { [day: string]: WorkingTime }).map(([day, workingTime]) => ({
          [day]: workingTime,
        })),
        currentDayIndex - 1,
      );
    }
  },
  { deep: true },
);

const firePhoneEvent = async () => {
  const userCategory = user.value?.account.business_type.split("|");
  const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;
  await nextTick(async () => {
    await pushCustomEvent(CustomEvents.BRANCH_TELEPHONE_INTERACTION, {
      logged_in: !!user.value,
      user_id: user.value?.id ?? null,
      customer_category_1: user ? isTrade : "Guest",
      customer_category_2: userCategory?.[1] ?? null,
      customer_category_3: userCategory?.[2] ?? null,
      account_type: user.value?.account.type ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
    });
  });
};

const fireEmailEvent = async () => {
  const userCategory = user.value?.account.business_type.split("|");
  const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;
  await nextTick(async () => {
    await pushCustomEvent(CustomEvents.BRANCH_EMAIL_ADDRESS_INTERACTION, {
      logged_in: !!user.value,
      user_id: user.value?.id ?? null,
      customer_category_1: user ? isTrade : "Guest",
      customer_category_2: userCategory?.[1] ?? null,
      customer_category_3: userCategory?.[2] ?? null,
      account_type: user.value?.account.type ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
    });
  });
};
</script>
